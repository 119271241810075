import { useId } from 'react';

import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { PagePlugFragment } from '@hultafors/hellberg/types';

import { PlugsGrid } from '../PlugsGrid/PlugsGrid';

import {
  ImageWrapper,
  PagePlugsStyled,
  PagePlugStyled,
  StyledLink,
} from './PagePlugs.styled';

interface PagePlugsProps {
  plugs: PagePlugFragment[];
}

export const PagePlugs: React.FC<PagePlugsProps> = ({ plugs }) => {
  const uid = useId();
  function plugMapper({ image, url, title }: PagePlugFragment, i: number) {
    if (!image?.responsiveImage?.src || !url || !title) {
      return null;
    }
    const sizes = [
      `(min-width: ${breakpoints.desktop}) 25vw`,
      `(min-width: ${breakpoints.tablet}) 28vw`,
      '100vw',
    ].join(', ');
    return (
      <PagePlugStyled key={`PagePlugs-${uid}-${i}`}>
        <ImageWrapper>
          <Image
            src={image.responsiveImage.src}
            alt={image.alt || title}
            placeholder={image.responsiveImage.base64 ? 'blur' : 'empty'}
            blurDataURL={image.responsiveImage.base64 || ''}
            sizes={sizes}
            fill
          />
        </ImageWrapper>
        <StyledLink href={url}>{title}</StyledLink>
      </PagePlugStyled>
    );
  }
  return (
    <PagePlugsStyled>
      <PlugsGrid total={plugs.length} gap={32}>
        {plugs.map(plugMapper)}
      </PlugsGrid>
    </PagePlugsStyled>
  );
};
