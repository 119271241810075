import Image from 'next/image';

import { AppLinksBlockFragment } from '@hultafors/hellberg/types';

import {
  AppLink,
  AppLinksStyled,
  Header,
  Links,
} from './app-links-block.styled';

export interface AppLinksBlockProps extends AppLinksBlockFragment {
  styles?: React.CSSProperties;
}

export const AppLinksBlock: React.FC<AppLinksBlockProps> = ({
  appstoreBadge,
  appstoreUrl,
  header,
  googlePlayBadge,
  googlePlayUrl,
  styles,
  scrollId,
}) => {
  const sanitizedScrollId = scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '');

  if (!appstoreBadge || !googlePlayBadge) {
    return null;
  }

  return (
    <AppLinksStyled id={sanitizedScrollId || undefined} style={styles}>
      <Header>{header}</Header>
      <Links>
        <AppLink target="_blank" href={appstoreUrl || ''}>
          <Image
            src={appstoreBadge?.url || ''}
            alt={appstoreBadge?.alt || 'App Store'}
            style={{ objectFit: 'contain' }}
            width={130}
            height={48}
          />
        </AppLink>
        <AppLink target="_blank" href={googlePlayUrl || ''}>
          <Image
            src={googlePlayBadge?.url || ''}
            alt={googlePlayBadge?.alt || 'Google play'}
            style={{ objectFit: 'contain' }}
            width={130}
            height={48}
          />
        </AppLink>
      </Links>
    </AppLinksStyled>
  );
};
