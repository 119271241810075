import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

interface StyledBulletListProps {
  $isBig?: boolean;
}
export const StyledBulletList = styled.div<StyledBulletListProps>`
  margin-block: ${spacing.medium};
  margin-inline: ${spacing.small};
  scroll-margin: var(--scroll-margin);

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block: ${spacing.medium};
    margin-inline: 0;
  }

  .content {
    h2 {
      padding-block-end: ${spacing.small};
    }

    ul {
      margin: 0;
      padding-block: 0;
      padding-inline: 17px;
      list-style: none;

      li {
        color: ${colors.black};
        font-size: ${({ $isBig }) =>
          $isBig ? fontSizes.header4 : fontSizes.body16};
        line-height: ${({ $isBig }) =>
          $isBig ? lineHeights.header4 : lineHeights.body16};
        font-family: ${({ $isBig }) =>
          $isBig ? fontFamilies.fontHero : fontFamilies.fontRegularBold};
        font-weight: ${fontWeights.fontMedium};

        a {
          color: ${colors.black};
        }
      }

      li::before {
        content: '\2022';
        color: ${colors.black};
        font-weight: bold;
        display: inline-block;
        inline-size: 1em;
        margin-inline-start: -1em;
      }
    }
  }
`;
