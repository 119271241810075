import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment } from '@hultafors/hellberg/types';

import ArrowRight from '../../svg/arrow_right.svg';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  CTAWrapper,
  GenericPlugStyled,
  ImageWrapper,
  TextBoxWrapper,
} from './GenericPlug.styled';

interface GenericPlugProps {
  title: string;
  url: string;
  image?: ImageFragment;
  description?: string;
  ctaLabel?: string;
}

export const GenericPlug: React.FC<GenericPlugProps> = ({
  title,
  url,
  image,
  description,
  ctaLabel,
}) => {
  const sizes = [
    '(min-width: 1852px) 33vw',
    '(min-width: 1232px) 40vw',
    `(min-width: ${breakpoints.desktop}) 85vw`,
    `(min-width: ${breakpoints.tablet}) 90vw`,
    '100vw',
  ].join(', ');

  return (
    <GenericPlugStyled href={url} passHref>
      {image?.responsiveImage?.src ? (
        <ImageWrapper>
          <Image
            src={image?.responsiveImage?.src}
            alt={image.alt || title}
            sizes={sizes}
            fill
            placeholder={image.responsiveImage.base64 ? 'blur' : 'empty'}
            blurDataURL={image.responsiveImage.base64 ?? undefined}
          />
        </ImageWrapper>
      ) : (
        <div className="NoImagePlaceholder" />
      )}
      <TextBoxWrapper $image={!!image}>
        <HTag type="h2" styleType="header4" className="TextBoxTitle">
          {title}
        </HTag>
        <Paragraph styleType="body16" className="TextBoxText">
          {description ? description : '\u00A0'}
        </Paragraph>
        <CTAWrapper>
          <ArrowRight
            aria-hidden={true}
            focusable={false}
            width={16}
            height={16}
          />
          {ctaLabel && (
            <Paragraph styleType="body16" className="TextBoxCTA">
              {ctaLabel}
            </Paragraph>
          )}
        </CTAWrapper>
      </TextBoxWrapper>
    </GenericPlugStyled>
  );
};
