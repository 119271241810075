import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';
const desktopMenuHeight = 600;

interface ActiveProps {
  $active?: boolean;
}

interface FromLeftProps {
  $fromLeft?: boolean;
}

interface FromTopProps {
  $fromTop?: boolean;
}

interface IsFilterProps {
  $isFilter?: boolean;
}

interface IsSearchProps {
  $isSearch?: boolean;
}

interface SmallProps {
  $small?: boolean;
}

type SlideInBackgroundProps = ActiveProps & FromTopProps;

export const SlideInBackground = styled.div<SlideInBackgroundProps>`
  width: 100%;
  height: 0;
  position: fixed;
  inset-block-start: ${({ $fromTop }) => ($fromTop ? spacing.menuHeight : 0)};
  inset-inline-start: 0;
  z-index: -1;
  overflow-x: hidden;
  background-color: rgb(35 33 32 / 30%);
  opacity: 0;
  transition: opacity 0.3s, z-index 0.1s 0.3s;

  ${({ $active, $fromTop }) =>
    $active &&
    css`
      height: 100%;
      z-index: ${$fromTop ? '9' : '10'};
      opacity: 1;
      transition: opacity 0.3s, z-index 0.1s;
    `}
`;

interface SlideInInsideProps
  extends ActiveProps,
    FromLeftProps,
    FromTopProps,
    IsFilterProps,
    IsSearchProps {
  $small?: boolean;
}

export const SlideInInside = styled.div<SlideInInsideProps>`
  position: absolute;
  inset-block-start: 0;
  inset-inline: ${({ $fromLeft }) => ($fromLeft ? '0 auto' : 'auto 0')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 12;
  box-sizing: border-box;
  background-color: ${colors.white};
  transform: ${({ $fromTop, $fromLeft }) =>
    $fromTop
      ? 'translateY(-110%)'
      : $fromLeft
      ? 'translateX(-110%)'
      : 'translateX(110%)'};
  transition: transform 0.3s ease-in-out;

  ${({ $active, $small, $isSearch, $fromTop }) =>
    $active &&
    css`
      transform: ${$small && !$isSearch
        ? 'translateY(-61%)'
        : $fromTop
        ? 'translateY(0%)'
        : 'translateX(0%)'};
      transition: transform 0.3s ease-in-out;
    `}

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    inline-size: 100%;
    max-inline-size: ${({ $fromTop }) => ($fromTop ? 'none' : '400px')};
    max-block-size: ${({ $fromTop, $isSearch }) =>
      $fromTop ? ($isSearch ? '152px' : `${desktopMenuHeight}px`) : 'none'};
    block-size: ${({ $fromTop, $isSearch }) =>
      $fromTop && !$isSearch ? 'auto' : 'inherit'};
  }
  ${({ $isFilter, $fromTop }) =>
    $isFilter &&
    css`
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        max-inline-size: ${$fromTop ? 'none' : '477px'};
      }
    `}
`;

export const SlideInHeader = styled.div<IsFilterProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  min-height: 56px;
  padding: 0 ${({ $isFilter }) => ($isFilter ? spacing.regular : spacing.small)};
  border-block-end: ${({ $isFilter }) =>
    $isFilter ? 'none' : `1px solid ${colors.gray4}`};

  .search & {
    display: none;
  }
`;

export const HeaderText = styled.div<{ $isMenu?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: ${fontSizes.header3};
    line-height: ${lineHeights.header3};
    font-family: ${fontFamilies.fontHero};
    color: ${colors.black};
  }

  button {
    overflow: visible;
    text-transform: none;
    border: none;
    appearance: none;
    display: flex;
    align-items: center;
    outline: none;
    background: none;
    padding-inline-start: 0;
  }
`;

export const HeaderIcon = styled.img`
  width: ${spacing.regular};
  height: ${spacing.regular};
  display: inline-flex;
  margin-inline-end: ${spacing.xsmall};

  @media screen and (min-width: ${breakpoints.mobile}) {
    margin-inline-end: ${spacing.small};
  }
`;

export const Extras = styled(Paragraph).attrs({
  styleType: 'body16Medium',
})`
  margin-inline-start: ${spacing.xsmall};
  color: ${colors.hellSalmon};
`;

export const SlideInExit = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: ${fontSizes.s};
    letter-spacing: 0.5px; /* 0.5px */
  }
`;

export const Clear = styled.button`
  display: flex;
  appearance: none;
  border: none;
  background-color: transparent;
  align-items: center;
  margin-inline-end: ${spacing.small};
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.xs};
  cursor: pointer;
  transition: 0.2s ease;

  svg {
    inline-size: ${spacing.small};
    block-size: ${spacing.small};
    margin-inline-start: ${spacing.xsmall};
  }

  &:hover {
    color: ${colors.blackHover};
  }
`;

export const CloseBtn = styled.button`
  display: flex;
  appearance: none;
  border: none;
  background-color: transparent;
  height: 100%;
  cursor: pointer;

  svg path {
    transition: 0.2s ease;
  }

  &:hover {
    color: ${colors.hellBlueHover};
  }
`;

interface SlideInBodyProps extends FromTopProps, IsSearchProps {
  $isCart?: boolean;
  $padContent?: boolean;
}

function bodyPadding({ $padContent, $isCart }: SlideInBodyProps) {
  if ($isCart) {
    return css`
      padding-block: 0 150px;
      padding-inline: ${spacing.small};
    `;
  }
  if ($padContent) {
    return css`
      padding-block: ${spacing.regular} ${spacing.medium};
      padding-inline: ${spacing.small};
    `;
  }
}

export const SlideInBody = styled.div<SlideInBodyProps>`
  ${bodyPadding};
  overflow: ${({ $fromTop }) => ($fromTop ? 'visible' : 'scroll')};
  height: ${({ $fromTop, $isSearch }) =>
    $fromTop && !$isSearch ? 'auto' : '100%'};
  width: 100%;
  box-sizing: border-box;
`;
