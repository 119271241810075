import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';

import { getFilterQuery, getFilterQueryObject } from '@hultafors/shared/api';
import { useUpdateQuery } from '@hultafors/shared/hooks';
import {
  ParsedProductsApiResponse,
  ProductFilter,
  ProductFilterValue,
  ProductListResponse,
  QueryProductFilter,
} from '@hultafors/shared/types';

import { parseIncomingFilters } from '@hultafors/hellberg/api';
import { PAGE_SIZE_L } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  CategoryPageFragment,
  GenericCategoryFragment,
  HellbergProduct,
} from '@hultafors/hellberg/types';

import { EmptyProducts } from '../EmptyProducts/EmptyProducts';
import { Filter } from '../Filter/Filter';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { Pager } from '../Pager/Pager';
import { Products } from '../Products/Products';
import { ProductsGrid } from '../ProductsGrid/ProductsGrid';
import { Section } from '../Section/Section';
import { SeoSection } from '../SeoSection/SeoSection';
import { SlideIn } from '../SlideIn/SlideIn';

interface CategoryPageContentProps {
  content: CategoryPageFragment;
  genericCategory: GenericCategoryFragment;
  fallbackData: ParsedProductsApiResponse<HellbergProduct>[];
}

export const CategoryPageContent: React.FC<CategoryPageContentProps> = ({
  content,
  genericCategory,
  fallbackData = [],
}) => {
  const { shared, siteLocale } = useGlobal();
  const { locale, ...router } = useRouter();
  const [filters, setFilters] = useState<ProductFilter[]>();
  const updateQuery = useUpdateQuery({
    filter: ['category', 'parentCategory'],
  });

  const selectedFilters = useMemo(
    () =>
      filters?.filter(
        ({ values }) => !!values.filter(({ active }) => active).length,
      ) || [],
    [filters],
  );

  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: ParsedProductsApiResponse<HellbergProduct[]> | null,
  ) => {
    if (
      previousPageData &&
      previousPageData.paging.pageCount === previousPageData.paging.pageNumber
    ) {
      return null;
    }

    const path = '/api/productslist';
    const searchParams = new URLSearchParams({
      includeFilter: 'true',
      market: siteLocale.parttrapMarket ?? '',
      pageNumber: `${index + 1}`,
      pageSize: `${PAGE_SIZE_L}`,
      productCatalogNodeId: content.products,
      siteId: siteLocale.parttrapSiteId ?? '',
    });

    if (siteLocale.parttrapLanguage) {
      searchParams.set('lang', siteLocale.parttrapLanguage);
    }

    if (filters) {
      Object.entries(getFilterQueryObject(filters ?? [])).forEach(
        ([key, value]) => {
          searchParams.set(key, value);
        },
      );
    }
    if (!filters && typeof window !== 'undefined') {
      const incomingFilters = new URLSearchParams(window.location.search);
      incomingFilters.forEach((value, key) => {
        if (key.startsWith('f')) {
          searchParams.set(key, value);
        }
      });
    }

    return [path, searchParams.toString()].join('?');
  };

  // Config swr to use server side data
  const swrConfig: SWRInfiniteConfiguration<
    ParsedProductsApiResponse<HellbergProduct>
  > = {
    fallbackData,
    initialSize: fallbackData?.length || 0,
  };

  // Initialize swr for product data fetching
  const { data, error, isLoading, size, setSize } = useSWRInfinite<
    ProductListResponse<HellbergProduct>
  >(getKey, swrConfig);

  const paging = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.paging;
  }, [data]);

  const products = useMemo(() => {
    return data?.flatMap(({ items }) => items) || [];
  }, [data]);

  const initialFilters = useMemo(() => {
    if (data?.length && data[0]?.filters) {
      return data[0].filters.flatMap((filters) => filters) || [];
    }
  }, [data]);

  useEffect(() => {
    if (data?.length) {
      if (!filters) {
        setFilters((previousState) => {
          if (previousState?.length) {
            return previousState;
          }

          let incomingFilters: QueryProductFilter[] = [];

          if (!filters && typeof window !== 'undefined') {
            incomingFilters = parseIncomingFilters(
              new URLSearchParams(window.location.search),
            );
          }
          return (
            data[data.length - 1]?.filters?.map(({ values, ...filter }) => {
              return {
                ...filter,
                values: values.map((value) => {
                  return {
                    ...value,
                    active:
                      incomingFilters.some(
                        ({ ValueId }) => ValueId === `${value.id}`,
                      ) ?? value.active,
                  };
                }),
              };
            }) ?? []
          );
        });
      }
    }
  }, [data]);

  /**
   * Handles changed filters further down the tree
   * @param param0 Filter to toggle
   */
  const filterChange = ({ AttrId, ValueId }: QueryProductFilter) => {
    const filterMapper = ({ values, ...filter }: ProductFilter) => {
      const valueMapper = (value: ProductFilterValue) => ({
        ...value,
        active:
          `${filter.id}` === `${AttrId}` && `${value.id}` === `${ValueId}`
            ? !value.active
            : value.active,
      });
      return {
        ...filter,
        values: values.map(valueMapper),
      };
    };
    setFilters((previousState) => {
      const newState: ProductFilter[] = initialFilters?.map(filterMapper) ?? [];
      if (previousState) {
        updateQuery(getFilterQuery(newState));
        setSize(1);
      }
      return newState;
    });
  };

  const clearFilters = () => {
    setFilters((previousState) => {
      const newState: ProductFilter[] =
        filters?.map((filter) => ({
          ...filter,
          values: filter.values.map((value) => ({ ...value, active: false })),
        })) ?? [];
      if (previousState?.length) {
        updateQuery(getFilterQuery(newState));
        setSize(1);
      }
      return newState;
    });
  };

  // TODO could be moved to someplace shared
  const showAll = () => {
    const newSize = paging?.pageCount;
    if (newSize) {
      setSize(newSize);
      const params = new URLSearchParams(
        router.query as Record<string, string>,
      );
      if (!newSize || newSize === 1) {
        params.delete('page');
      } else {
        params.set('page', `${newSize}`);
      }

      updateQuery(params);
    }
  };

  // TODO could be moved to someplace shared
  const loadMore = () => {
    const newSize = size + 1;
    setSize(newSize);
    const params = new URLSearchParams(router.query as Record<string, string>);
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }

    updateQuery(params);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <Filter
        filters={initialFilters ?? []}
        filterLabel={genericCategory?.filter || ''}
        selectedFilters={selectedFilters}
        filterChange={filterChange}
        clearFilter={clearFilters}
        clearFilterLabel={genericCategory.resetFilters || ''}
        toggleFilter={toggleFilter}
        typeLabel={genericCategory?.typeLabel || ''}
      />
      <Section noMarginTop>
        {!!products.length && (
          <ProductsGrid>
            <Products
              products={products}
              badgeText={shared?.new ?? ''}
              rrpLabel={shared?.rrpLabel ?? ''}
              rrpIncludingVatLabel={shared?.rrpIncludingVatLabel ?? ''}
            />
          </ProductsGrid>
        )}
        {!products.length && (
          <EmptyProducts>{genericCategory.empty}</EmptyProducts>
        )}

        <Pager
          showMoreText={genericCategory.showMoreProducts || ''}
          showAllText={genericCategory.viewAll || ''}
          ofText={genericCategory.of || ''}
          productsText={genericCategory.products || ''}
          loadMore={loadMore}
          showAll={showAll}
          pagerData={paging}
          loading={isLoading}
        />
        <SeoSection
          title={content.seoSectionTitle || ''}
          description={content.seoSectionDescription || ''}
        />
      </Section>

      <SlideIn
        isOpen={isFilterOpen}
        toggle={toggleFilter}
        headerText={genericCategory.filter || ''}
        padContent
        clearText={genericCategory.resetFilters || ''}
        clearAll={clearFilters}
        itemLength={selectedFilters.length}
        closeLabel={shared?.closeLabel || ''}
      >
        <MobileFilter filters={filters ?? []} filterChange={filterChange} />
      </SlideIn>
    </>
  );
};
