import { createGlobalStyle, css } from 'styled-components';

import { fontWeights } from '@hultafors/hellberg/helpers';

const cssVariables = css`
  :root {
    --header-height: 64px;
    --map-offset: 0;
    --map-width: 100%;
    --map-height: 500px;
    --map-height-desktop: 600px;
    --scroll-margin: calc(var(--header-height) + 20vh);
  }
`;

const globalStyle = css`
  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    background-repeat: no-repeat; /* 2 */
  }

  html {
    font-size: 16px;
    min-height: 100vh;
  }

  .hascookiereport {
    word-break: initial;
  }

  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: ${fontWeights.fontRegular};
  }

  #root {
    min-height: 100vh;
  }

  img {
    max-width: 100%;
  }

  .no-js img.lazyload {
    display: none;
  }

  .map {
    position: relative;
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h6,
  p {
    padding: 0;
    margin: 0;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${cssVariables};
  ${globalStyle};
`;
